import { CSSObject } from '@emotion/react';
import create from 'zustand';
import { isDevice } from './utils';

type UseScrollLockState = {
    // The current open state
    isLocked: boolean;

    // Specify the open state
    setIsLocked: (isLocked: boolean) => void;

    lock: () => void;
    unlock: () => void;
};

const deviceLockStyles: CSSObject = {
    overflow: 'hidden',
    touchAction: 'none',
};

const desktopLockStyles: CSSObject = {
    position: 'relative',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: 'hidden',
    height: '100%',
};

const scrollUnlockStyles = {
    overflow: '',
    touchAction: '',
    position: '',
    top: '',
    bottom: '',
    left: '',
    right: '',
};

export const useScrollLock = create<UseScrollLockState>((set) => ({
    isLocked: false,
    setIsLocked: (isLocked: boolean) => set({ isLocked }),
    lock: () => {
        const doc = document.documentElement;

        set({ isLocked: true });

        if (isDevice()) {
            Object.assign(doc.style, deviceLockStyles);
            return;
        }

        Object.assign(doc.style, desktopLockStyles);
    },

    unlock: () => {
        const doc = document.documentElement;

        set({ isLocked: false });

        Object.assign(doc.style, scrollUnlockStyles);

        if (isDevice()) {
            return;
        }
    },
}));
