import { CAPIError } from '~/lib/data-contract/salesforce';

export class HTTPError extends Error {
    private _type?: string;

    constructor(public status: number, public message: string, type?: string) {
        super(message);
        this._type = type;
    }

    toString() {
        return `HTTPError ${this.status}: ${this.message}`;
    }

    get type() {
        return this._type;
    }
}

export class HTTPNotFound extends HTTPError {
    constructor(message: string) {
        super(404, message);
    }
}

type GiftCardErrorData = {
    giftCertificateCode: string;
};

export class GiftCardError extends HTTPError {
    private _data?: GiftCardErrorData;

    constructor(public status: number, message: string, data?: Record<string, unknown>) {
        super(status, message);
        this._data = data as GiftCardErrorData;
    }

    get data() {
        return this._data;
    }
}

export class MultiError extends HTTPError {
    private _data?: CAPIError[];

    constructor(public status: number, message: string, data?: CAPIError[]) {
        super(status, message);
        this._data = data;
    }

    get data() {
        return this._data;
    }
}
