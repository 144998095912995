import { URL } from 'url';
import type { Frame, Pages, Redirect } from '~/lib/data-contract';
import { Translations } from '~/shared/utils/translation';
import type * as http from 'http';

type Fetch<T> = Omit<Response, 'json'> & {
    json: () => Promise<T>;
};

type ParamType = {
    [key: string]: string | string[] | undefined;
};

const { API_URL } = process.env;

/**
 * Helper method for fetching content from the API
 *
 * @param path The API path without endpoint
 * @param params Additional params for the url
 * @param headers Headers to append
 */
async function fetchAPI<T>(
    path: string,
    params: ParamType = {},
    headers: HeadersInit = {}
): Promise<Fetch<T>> {
    // Build url
    const url = new URL(path, API_URL);

    // Add query params
    Object.entries(params).forEach(([key, value]) => {
        url.searchParams.append(key, String(value));
    });

    console.info('Querying', url.href, headers);

    const response = await fetch(url.href, {
        method: 'GET',
        headers: {
            ...headers,
            'Content-Type': 'application/json; charset=utf-8',
        },
        keepalive: true,
    });

    const { status, statusText } = response;

    if (status >= 500) {
        console.error(`Failed to fetch API with url:${url}`);
        console.error(statusText);
    }

    return response;
}

/**
 * Helper method for getting the data from a frame
 *
 * @see fetchFrame
 */
export async function getFrameData(url: string, params = {}) {
    const response = await fetchFrame(url, params);
    return response.json();
}

/**
 * Helper method for requesting a frame from the api.
 * Returns headers, status and data
 *
 * @param url The URL for the frame
 * @param params Additional params for the request. Note these params are not added to the provided URL but to the API requst
 */
export async function fetchFrame(url: string, params = {}) {
    return fetchAPI<Frame>(`api/content/frame`, {
        url,
        ...params,
    });
}

/**
 * Helper method for requesting a page from the api.
 * Returns headers, status and data
 *
 * @param url The URL for the page
 * @param params Additional params for the request. Note these params are not added to the provided URL but to the API requst
 * @param headers Headers to append
 */
export async function fetchPage(url: string, params = {}, headers: HeadersInit = {}) {
    return fetchAPI<Pages | Redirect>(
        `api/content/page`,
        {
            url,
            ...params,
        },
        headers
    );
}

export async function fetchBrands(params = {}, headers: HeadersInit = {}) {
    return fetchAPI<Pages | Redirect>(
        `api/brands/list`,
        {
            ...params,
        },
        headers
    );
}

export async function fetchRedirect(url: string) {
    const callUrl = `${process.env.REDIRECTS_API}/integrations/Redirects/Lookup?url=${url}`;
    return fetch(callUrl, {
        headers: {
            [process.env.API_SECURITY_KEY as string]: process.env.API_SECURITY_VALUE as string,
        },
    });
}

/**
 * Helper method for getting the data from a page
 *
 * @see fetchPage
 */
export async function getPageData(url: string, params = {}) {
    const response = await fetchPage(url, params);
    return response.json();
}

/**
 * Helper method for requesting translations for a particular culture
 *
 * @param culture language, determined by CMS
 * @returns data in the type of Translations
 */
export async function getTranslationData(url = '/') {
    const response = await fetchAPI<Translations>(`api/content/translations?url=${url}`);
    return response.json();
}

/**
 * Helper method for setting security headers for API Management
 *
 * @param proxyReq proxy request
 */
export const setApiSecurityHeader = (proxyReq: http.ClientRequest) => {
    if (process.env.API_SECURITY_KEY && process.env.API_SECURITY_VALUE) {
        proxyReq.setHeader(process.env.API_SECURITY_KEY, process.env.API_SECURITY_VALUE);
    }
};
