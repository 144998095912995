export const fetcher = <T>(url: RequestInfo, options?: RequestInit) => async () => {
    const response = await fetch(url, options);
    const contentType = response.headers.get('content-type') || '';

    if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText} at ${url}`);

        // Currently only support JSON
    } else if (!contentType.includes('application/json')) {
        throw Error(`Content type ${contentType} handling not implemented`);
    }

    const data: T = await response.json();
    return data;
};
