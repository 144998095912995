import { sizes } from './sizes';

export const breakpointsRaw = {
    xs: 320,
    /**
     * Larger phones, tablet portrait
     */
    sm: 768,
    /**
     * Larger phones, tablet portrait
     */

    /**
     * When the site should switch between mobile mode and desktop mode
     */
    desktop: 1024,

    md: 1024,
    /**
     * When the frame should break between mobile / desktop
     */
    lg: 1440,
    /**
     * Desktops, laptops
     */
    xl: 1600,
    /**
     * Beyond max content width
     */
    max: 1920,

    /**
     * Point where content stops growing
     */
    contentMax: sizes.contentMaxWidth,

    /**
     * Beyond max content width
     */
    pageMax: sizes.pageMaxWidth,
};

export type Breakpoints = typeof breakpointsRaw;
export type BreakpointKeys = keyof Breakpoints;

export const queries = {
    xs: `(max-width: ${breakpointsRaw.sm - 1}px)`,
    sm: `(min-width: ${breakpointsRaw.sm}px)`,
    md: `(min-width: ${breakpointsRaw.md}px)`,
    lg: `(min-width: ${breakpointsRaw.lg}px)`,
    xl: `(min-width: ${breakpointsRaw.xl}px)`,
    max: `(min-width: ${breakpointsRaw.max}px)`,
    contentMax: `(min-width: ${breakpointsRaw.contentMax}px)`,
    pageMax: `(min-width: ${breakpointsRaw.pageMax}px)`,
    reducedMotion: `(prefers-reduced-motion)`,
    desktop: `(max-width: ${breakpointsRaw.desktop - 1}px)`,
    hasHover: '(hover: hover)',
};

export const breakpoints = {
    xs: `@media ${queries.xs}`,
    sm: `@media ${queries.sm}`,
    md: `@media ${queries.md}`,
    lg: `@media ${queries.lg}`,
    xl: `@media ${queries.xl}`,
    max: `@media ${queries.max}`,
    contentMax: `@media ${queries.contentMax})`,
    pageMax: `@media ${queries.pageMax}`,
    desktop: `(@media ${queries.desktop})`,

    /**
     * Prefers reduced motion
     */
    reducedMotion: `@media ${queries.reducedMotion}`,

    /* Only for screens that supports hover */
    hasHover: `@media ${queries.hasHover}`,
};
