import React from 'react';
import { RequestProvider } from '~/shared/utils';
import type { NextPage } from 'next';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import NextNProgress from 'nextjs-progressbar';
import { ReactElement, ReactNode } from 'react';
import { CommerceAPIProvider } from '~/features/commerce-api/contexts';
import { useIsDebug } from '~/shared/hooks/useIsDebug';
import { GoogleAnalytics4 } from '~/shared/scripts/GoogleAnalytics4';
import { RaptorHeadScript } from '~/shared/scripts/RaptorHead';
import { reactPlugin } from '~/shared/utils/errorBoundary/appInsights';
import { AppInsightsProvider } from '~/shared/utils/errorBoundary/context/AppInsightsProvider';
import { FrameProvider } from '~/shared/utils/frame';
import { IMarket, MarketProvider } from '~/shared/utils/market';
import { TranslationProvider } from '~/shared/utils/translation';
import { DynamicPageProps } from '~/templates/pages';
import { orangeMedium } from '~/theme/colors';
import { HydrationBoundary } from '@tanstack/react-query';
import { ThemeProvider } from '~/theme/context/ThemeProvider';

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<DynamicPageProps> & {
    Component: NextPageWithLayout;
};

export const App = ({ Component, pageProps }: AppPropsWithLayout): JSX.Element | null => {
    const isDebug = useIsDebug();
    const getLayout = Component.getLayout ?? ((page) => page);
    const RenderComponent = Component as any;

    const { frame, translations, page } = pageProps;

    const culture = frame?.culture ?? 'da-dk';

    const currentMarket: IMarket = {
        culture,
        market: page?.market ?? 'dk',
    };

    if (!frame) return <></>;

    return (
        <>
            {/* Scripts */}
            <GoogleAnalytics4 id={frame.settings?.googleTagManagerId} />
            <RaptorHeadScript customerId={frame.settings?.raptorCustomerId} />

            {/* GTM Styling */}
            {!!frame.settings?.googleTagManagerId && (
                <noscript>
                    <iframe
                        src={`https://tracking.imerco.dk/ns.html?id=${frame.settings.googleTagManagerId}`}
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                    ></iframe>
                </noscript>
            )}

            {/* Progress bar */}
            <NextNProgress
                color={orangeMedium}
                height={4}
                startPosition={0.6}
                showOnShallow={false}
                options={{
                    showSpinner: false,
                    easing: 'ease',
                    speed: 500,
                    minimum: 0.3,
                    trickle: false,
                    trickleSpeed: 50,
                    parent: '#progress-loader',
                }}
            />

            {/* App */}
            <AppInsightsProvider plugin={reactPlugin}>
                <CommerceAPIProvider>
                    <RequestProvider data={page?.data}>
                        <HydrationBoundary state={pageProps.dehydratedState}>
                            <ThemeProvider>
                                <MarketProvider value={currentMarket}>
                                    <TranslationProvider
                                        culture={culture}
                                        initialTranslations={translations}
                                        debug={isDebug}
                                    >
                                        <FrameProvider initialData={frame}>
                                            {getLayout(<RenderComponent {...pageProps} />)}
                                        </FrameProvider>
                                    </TranslationProvider>
                                </MarketProvider>
                            </ThemeProvider>
                        </HydrationBoundary>
                    </RequestProvider>
                </CommerceAPIProvider>
            </AppInsightsProvider>
        </>
    );
};

export function reportWebVitals(metric: NextWebVitalsMetric): void {
    if (process.env.NODE_ENV !== 'production') {
        console.debug('Webvitals', metric.name, Math.round(metric.value * 100) / 100, 'ms');
    }
}

export default App;
