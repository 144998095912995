import Script from 'next/script';

type Props = {
    customerId: string | undefined;
};

export const RaptorHeadScript: React.FC<Props> = ({ customerId }: Props) => {
    if (!customerId) return null;

    return (
        <Script
            id="raptor-head"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
      window.raptor||(window.raptor={q:[{event:"trackevent",params:{p1:"pageview"}}],push:function(event,params,options){this.q.push({event:event,params:params,options:options})},customerId:"${customerId}"});
      `,
            }}
        />
    );
};
