import { useCallback, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetcher } from '~/shared/utils/request/utils/fetcher';
import { TranslationContext } from '../context/TranslationProvider';
import { Translations } from '../model/translationModel';
import { namespacedKeys } from '../utils/namespacedKeys';
import { interpolate } from '../utils/interpolateText';
import { TranslationKey } from '~/lib/data-contract';

/**
 * Translation hook, will load initial translations provided by context on initial load, and fetch new values when culture changes
 * Always try to provide a value, even if it's from the previously loaded translations, but the isLoading will indicate
 * if it's serving stale data
 */
export type translationInterpolationKeys = {
    [key: string]: string;
};

export type TranslateFn = (
    key: TranslationKey,
    interpolationKeys?: translationInterpolationKeys,
) => string;

export const useTranslation = () => {
    const { culture, initialTranslations: initialData, debug } = useContext(TranslationContext);

    const { data, isLoading } = useQuery<Translations | null, Error>({
        queryKey: ['/api/proxy/content/translations', culture],
        queryFn: fetcher<Translations | null>('/api/proxy/content/translations', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ culture }),
        }),
        initialData,
    });

    const translations = useMemo(() => namespacedKeys(data || {}), [data]);

    const translate: TranslateFn = useCallback(
        (key: TranslationKey, interpolationKeys?: translationInterpolationKeys) => {
            if (debug) {
                return (
                    '[' +
                    (key +
                        (interpolationKeys
                            ? ' ' + Object.keys(interpolationKeys).map((x) => `{{${x}}}`)
                            : '')) +
                    ']'
                );
            }

            const translation = translations[key];

            if (!translation) {
                console.warn(`Missing translation: ${key}`);
                return '';
            }

            return interpolationKeys ? interpolate(translation, interpolationKeys) : translation;
        },
        [translations],
    );

    return { translate, isLoading };
};
