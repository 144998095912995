export const sizes = {
    // Limit content
    contentMaxWidth: 1520,

    // Limit page width. Background colors etc.
    pageMaxWidth: 1520,

    leftContent: 768,
    content: 'min(88vw, 1690px)',
    banner: '48px',
    cornerRadius: '4px',
    cornerRadiusLarge: '6px',
    summaryBasket: '414px',
};
