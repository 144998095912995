export const black = '#231F20';
export const black80 = '#4F4C4D';
export const black70 = '#656262';

export const grey100 = '#87888B';
export const grey80 = '#ABABAD';
export const grey50 = '#AEAEAF';
export const grey40 = '#D1D2D4';
export const grey30 = '#E2E2E4';
export const grey20 = '#F1F1F2';
export const grey10 = '#F7F7F8';
export const white = '#FFFFFF';

export const imercoOrangeDark = '#9F5457';
export const brandOrangeDark = '#F04D22';
export const brandOrangeMedium = '#F68A20';
export const brandOrangeLight = '#F7C18C';
export const brandOrangeUltraLight = '#FCE1CF';
export const brand60 = '#F8A14D';
export const brand70 = '#F8AD62';

export const brownDark = '#AF8A6A';
export const brownMedium = '#D0BEA4';
export const brownLight = '#EBE3DB';
export const brownUltraLight = '#FCFAF9';

export const blueDark = '#00264D';
export const blueMedium = '#0059B3';
export const blueLight = '#519FED';
export const blueUltraLight = '#B6D9FF';

export const greenDark = '#2D4734';
export const greenMedium = '#5F8F82';
export const greenLight = '#77CCB5';
export const greenUltraLight = '#C3F0DE';

export const orangeDark = brandOrangeDark;
export const orangeMedium = brandOrangeMedium;
export const orangeLight = brandOrangeLight;
export const orangeUltraLight = brandOrangeUltraLight;

export const yellowDark = '#FFDE69';
export const yellowMedium = '#FFF18A';
export const yellowLight = '#FFF8BA';
export const yellowUltraLight = '#FFFBDC';

export const redDark = '#54001D';
export const redMedium = '#D71E24';
export const redLight = '#E85562';
export const redUltraLight = '#FFDDDF';

export const primary = brandOrangeMedium;
export const secondary = brandOrangeLight;
export const campaign = greenMedium;
export const positive = greenMedium;
export const neutral = brownLight;
export const negative = redMedium;
export const disabled = grey80;

//--------------- Custom colors -------------------------

export const headerColor = '#FFFFFF';
export const headerTextColor = '#231F20';
export const headerSearchInputBorderColor = '#EBE3DB';

export const productImageBackround = brownUltraLight;
export const onSale = brandOrangeDark;

export const grey90 = '#525151';
export const grey70 = '#928F90';
export const blue = blueMedium;
export const red50 = redMedium;
export const green50 = greenMedium;

export const borderColor = grey50;
