import { useContext, useState } from 'react';
import { MarketContext } from '../context/MarketProvider';

export const convertCountryCodeToUppercase = (locale: string) => {
    const split = locale.split('-');
    return `${split[0]}-${split[1].toUpperCase()}`;
};

export const useMarket = () => {
    const market = useContext(MarketContext);
    const [isoCulture] = useState<string>(() => {
        if (!market.culture?.length) return '';
        return convertCountryCodeToUppercase(market.culture);
    });
    return { ...market, isoCulture };
};
