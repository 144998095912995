import React, { useMemo } from 'react';
import { Frame } from '~/lib/data-contract';

interface FrameProviderProps {
    initialData?: Frame | null;
}

export const FrameContext = React.createContext<FrameProviderProps>({});

export const FrameProvider = ({
    children,
    initialData,
}: React.PropsWithChildren<FrameProviderProps>) => {
    /**
     * Memoizing a context value will prevent unnecessary re-renders because
     * the "reference" will be the same for the object when doing comparisons
     * in consequent renders unless the passed values change.
     */
    const memoizedContextValue = useMemo(
        () => ({
            initialData,
        }),
        [initialData],
    );

    return <FrameContext.Provider value={memoizedContextValue}>{children}</FrameContext.Provider>;
};
