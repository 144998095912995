import getConfig from 'next/config';

type props = {
    NEXT_PUBLIC_SALESFORCE_CLIENT_ID: string;
    NEXT_PUBLIC_SALESFORCE_ORGANIZATION_ID: string;
    NEXT_PUBLIC_SALESFORCE_SHORTCODE: string;
    NEXT_PUBLIC_SALESFORCE_SITE_ID: string;
    NEXT_PUBLIC_URL: string;
    NEXT_PUBLIC_ALGOLIA_APP: string;
    NEXT_PUBLIC_ALGOLIA_TOKEN: string;
    NEXT_PUBLIC_ALGOLIA_INDEX: string;
    NEXT_PUBLIC_GOOGLE_MAPS_API: string;
    NEXT_PUBLIC_CLOUDFLARE_LOADER_URL: string;
    NEXT_PUBLIC_ALGOLIA_ENV: string;
    NEXT_PUBLIC_SALESFORCE_CLIENT_ACCOUNTAPI_CLIENTID: string;
    SPIFF_APPLICATION_KEY: string;
    SPIFF_INTEGRATION_ID: string;
    aiInstrumentationKey: string;
    isProduction: boolean;
};

type serverProps = {
    NEXT_PUBLIC_SALESFORCE_CLIENT_ID: string;
    NEXT_PUBLIC_SALESFORCE_ORGANIZATION_ID: string;
    NEXT_PUBLIC_SALESFORCE_SHORTCODE: string;
    NEXT_PUBLIC_SALESFORCE_SITE_ID: string;
    NEXT_PUBLIC_URL: string;
    NEXT_PUBLIC_SALESFORCE_CLIENT_ACCOUNTAPI_CLIENTID: string;
    aiInstrumentationKey: string;
};

const publicConfig: props = {
    NEXT_PUBLIC_SALESFORCE_CLIENT_ID: process.env.NEXT_PUBLIC_SALESFORCE_CLIENT_ID || '',
    NEXT_PUBLIC_SALESFORCE_ORGANIZATION_ID:
        process.env.NEXT_PUBLIC_SALESFORCE_ORGANIZATION_ID || '',
    NEXT_PUBLIC_SALESFORCE_SHORTCODE: process.env.NEXT_PUBLIC_SALESFORCE_SHORTCODE || '',
    NEXT_PUBLIC_SALESFORCE_SITE_ID: process.env.NEXT_PUBLIC_SALESFORCE_SITE_ID || '',
    NEXT_PUBLIC_URL: process.env.NEXT_PUBLIC_URL || '',
    NEXT_PUBLIC_ALGOLIA_APP: process.env.NEXT_PUBLIC_ALGOLIA_APP || '',
    NEXT_PUBLIC_ALGOLIA_TOKEN: process.env.NEXT_PUBLIC_ALGOLIA_TOKEN || '',
    NEXT_PUBLIC_ALGOLIA_INDEX: process.env.NEXT_PUBLIC_ALGOLIA_INDEX || '',
    NEXT_PUBLIC_GOOGLE_MAPS_API: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API || '',
    NEXT_PUBLIC_CLOUDFLARE_LOADER_URL: process.env.NEXT_PUBLIC_CLOUDFLARE_LOADER_URL || '', // Server-side config, handle separately if needed
    NEXT_PUBLIC_ALGOLIA_ENV: process.env.NEXT_PUBLIC_ALGOLIA_ENV || '',
    NEXT_PUBLIC_SALESFORCE_CLIENT_ACCOUNTAPI_CLIENTID:
        process.env.NEXT_PUBLIC_SALESFORCE_CLIENT_ACCOUNTAPI_CLIENTID || '',
    SPIFF_APPLICATION_KEY: process.env.SPIFF_APPLICATION_KEY || '',
    SPIFF_INTEGRATION_ID: process.env.SPIFF_INTEGRATION_ID || '',
    aiInstrumentationKey: '', // Server-side config, handle separately if needed
    isProduction: process.env.NODE_ENV === 'production',
};

const serverConfig: serverProps = {
    NEXT_PUBLIC_SALESFORCE_CLIENT_ID: process.env.NEXT_PUBLIC_SALESFORCE_CLIENT_ID || '',
    NEXT_PUBLIC_SALESFORCE_ORGANIZATION_ID:
        process.env.NEXT_PUBLIC_SALESFORCE_ORGANIZATION_ID || '',
    NEXT_PUBLIC_SALESFORCE_SHORTCODE: process.env.NEXT_PUBLIC_SALESFORCE_SHORTCODE || '',
    NEXT_PUBLIC_SALESFORCE_SITE_ID: process.env.NEXT_PUBLIC_SALESFORCE_SITE_ID || '',
    NEXT_PUBLIC_URL: process.env.NEXT_PUBLIC_URL || '',
    NEXT_PUBLIC_SALESFORCE_CLIENT_ACCOUNTAPI_CLIENTID:
        process.env.NEXT_PUBLIC_SALESFORCE_CLIENT_ACCOUNTAPI_CLIENTID || '',
    aiInstrumentationKey: '',
};

export const useConfig = () => {
    try {
        const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
        return {
            config: publicRuntimeConfig as props,
            serverConfig: serverRuntimeConfig as serverProps,
        };
    } catch {
        return { config: publicConfig, serverConfig };
    }
};
