/**
 * Frame hook (top menu, footer, settings, and other things that are not page-specific).
 * Will load initial data provided by context on initial load, and fetch new values when culture or market changes
 * Always try to provide a value, even if it's from the previously loaded frame, but the isLoading will indicate
 * if it's serving stale data
 */

import { useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { fetcher } from '~/shared/utils/request/utils/fetcher';
import { FrameContext } from '../context/FrameProvider';
import { Frame } from '..';
import { useMarket } from '~/shared/utils/market';
import { useConfig } from '~/shared/hooks';

export const useFrame = () => {
    const { initialData } = useContext(FrameContext);
    const { culture, market } = useMarket();
    const { config } = useConfig();
    const { asPath } = useRouter();

    const url = new URL(asPath, config.NEXT_PUBLIC_URL);

    const { data: frameData, isLoading } = useQuery<Frame | null, Error>({
        queryKey: ['/api/proxy/content/frame', url.pathname, culture, market],
        queryFn: fetcher<Frame | null>(`/api/proxy/content/frame?url=${url.pathname}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }),
    });

    /**
     * While this action is inexpensive, this will have different references
     * on each re-render consequently causing re-renders on subscribed components.
     * We will memoize it to keep the reference consistent until necessary.
     */
    const data: Frame = useMemo(
        () => ({
            market: {},
            ...initialData,
            ...frameData,
        }),
        [initialData, frameData],
    );

    return {
        data,
        isLoading,
    };
};
