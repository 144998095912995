import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { useConfig } from '~/shared/hooks';

const browserHistory = typeof window !== 'undefined' ? createBrowserHistory() : null;
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: useConfig().config?.aiInstrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory ?? null },
        },
        disableCookiesUsage: true,
    },
});
if (typeof window !== 'undefined' && useConfig().config?.aiInstrumentationKey) {
    appInsights.loadAppInsights();
}
