'use client';
/* eslint @typescript-eslint/no-empty-interface: "off" */
/* eslint @typescript-eslint/ban-types: "off" */

import { FaqProps, USPProps } from '~/shared/components/Summary/Summary.definitions';
import {
    P50ProductDetailsPage,
    PDP_SSR_Data,
} from '~/features/product-details-page/pdp.definitions';
import { TextVariants } from '~/theme/text-styles';
import { P80FavoritePageProps } from '~/features/favorites/favorites.definitions';
import { StoreResponse } from '../salesforce';
import { TCategoryPickerItem } from '~/features/productList/components/CategoryPickerItem/CategoryPickerItem';
import { Store_SSR_Data } from '~/features/find-store/store.definitions';
import { ComponentType } from 'react';
export interface ModuleTheme {
    backgroundColor?: string;
    textColor?: string;
}

export interface ModuleSpacing {
    spacingTop?: ModuleSpace;
    spacingBottom?: ModuleSpace;
}

export type ModuleSpace = 'none' | 'xs' | 'small' | 'default';

export type ModuleMediaLayout = 'full' | 'split';

export type ModuleHeight = 'narrow' | 'normal' | 'tall';

export type SSR_Data = PDP_SSR_Data & Store_SSR_Data;

export interface M70MediaItem {
    image?: ImageMedia;
    imageAltText?: string;
    video?: VideoMedia;
}

export interface MediaAndTextItem {
    alias?: 'contentArticle';
    image?: ImageMedia;
    imageAltText?: string;
    video?: VideoMedia;
    headline?: string;
    description?: RichText;
    verticalAlignment?: VerticalAlignment;
    callToAction?: Link;
}

export type Category = ModuleTheme & {
    title?: string;
    alias?: 'category';
    image?: ImageMedia;
    imageAltText?: string;
    callToAction?: Link;
    imageShadow?: boolean;
    headline?: Headline;
    subHeadline?: Headline;
    subSubHeadline?: Headline;
    verticalAlignment?: VerticalAlignment;
    horizontalAlignment?: HorizontalAlignment;
};

export type Module = (ModuleTheme & ModuleSpacing) & {
    id: string;
    type: string;
    priority?: boolean;
};

export type Modules =
    | M10HeroModule
    | M15HeroPromotionModule
    | M20CategoriesModule
    | M21ReferencedCategoriesModule
    | M30ProductCarouselModule
    | M31RaptorProductCarouselModule
    | M60UspModule
    | M70MediaModule
    | M75MediaAndTextModule
    | M80MediaAndTextBundleModule
    | M130CustomScriptModule
    | M90QuoteModule
    | M100RichTextModule
    | M120AccordionListModule
    | M140AnchorModule
    | (M10HeroModule &
          M15HeroPromotionModule &
          M20CategoriesModule &
          M21ReferencedCategoriesModule &
          M31RaptorProductCarouselModule &
          M60UspModule &
          M70MediaModule &
          M75MediaAndTextModule &
          M80MediaAndTextBundleModule &
          M90QuoteModule &
          M100RichTextModule &
          M120AccordionListModule);

export type ButtonColorProps =
    | 'link'
    | 'primary'
    | 'secondary'
    | 'campaign'
    | 'brand'
    | 'plain'
    | 'icon'
    | 'filter'
    | 'plp'
    | 'inverse-plp'
    | 'tag'
    | 'pdp'
    | 'pdp-secondary'
    | 'pdp-square'
    | 'alternative';

export type M10HeroModule = Module & {
    type: 'm10HeroModule';
    mediaLayout: ModuleMediaLayout;
    image?: ImageMedia;
    imageAltText?: string;
    video?: VideoMedia;
    mediaHorizontalAlignment: HorizontalAlignment;
    height?: ModuleHeight;
    horizontalAlignment?: HorizontalAlignment;
    verticalAlignment?: VerticalAlignment;
    headline?: Headline;
    subHeadline?: Headline;
    subSubHeadline?: Headline;
    description?: RichText;
    callToAction?: Link;
    color?: ButtonColorProps;
    alternativeBackgroundColor?: string;
    alternativeTextColor?: string;
};

export type M15HeroPromotionModule = (Module & VerticalAlignment) & {
    type: 'm15HeroPromotionModule';
};

export type M20CategoriesModule = Module & {
    type: 'm20CategoriesModule';
    items?: Category[];
    headline?: Headline;
    subHeadline?: Headline;
    subSubHeadline?: Headline;
    callToAction?: Link;
    shownAs?: 'Standard' | 'Carousel' | 'Tiles';
};

export type M21ReferencedCategoriesModule = Module & {
    type: 'm21ReferencedCategoriesModule';
    items?: PageReference[];
    headline?: Headline;
    subHeadline?: Headline;
    callToAction?: Link;
};

export enum UmbracoAlgoliaCategories {
    'Category Id' = 'cust_categories.id',
    'Brand Id' = 'brand',
    'Series Id' = 'cust_series',
    'Context Rule Name' = '',
    'Custom' = 'custom',
}

export type M30ProductCarouselModule = Module & {
    type: 'm30ProductCarouselModule';
    headline?: Headline;
    subHeadline?: Headline;
    callToAction?: Link;
    algoliaFilter: string;
    algoliaFilterType?: keyof typeof UmbracoAlgoliaCategories;
};

export type RaptorDropdownTypes =
    | 'GetOverAllTopSelling'
    | 'GetCookieRecommendations'
    | 'RelatedOrSimilarProductsOthersBought'
    | 'GetRelatedItems'
    | 'RelatedAndSimilarProductsOthersBought'
    | 'GetRelatedItemsForBasket'
    | 'GetTopSellingInCategory'
    | 'GetTopPurchasedInBrands'
    | 'GetCookieHistory'
    | 'GetTrendingBrands'
    | 'GetSimilarItems'
    | 'GetTrendingItems'
    | 'GetPopularItemsInCategoryWeb'
    | 'GetPopularItemsInBrandWeb'
    | 'GetUserItemRecommendationsWeb'
    | 'GetMerchandisingItemsWeb'
    | 'GetPopularItemsWeb';

export type M31RaptorProductCarouselModule = Module & {
    type: 'm31RaptorCarouselModule';
    headline?: Headline;
    subHeadline?: Headline;
    callToAction?: Link;
    raptorAlgorithm: RaptorDropdownTypes;
    raptorParameter?: string;
    productId?: string;
    productBrand?: string;
    productCategory?: string;
    merchandisingCampaignId?: string;
};

export type M60UspModule = Module & { type: 'm60UspModule'; items: UspItem[] };

export type M70MediaModule = (Module & M70MediaItem) & { type: 'm70MediaModule'; caption?: string };

export type M75MediaAndTextModule = Module & {
    type: 'm75MediaAndTextModule';
    mediaSize: 'default' | 'small';
    image?: ImageMedia;
    imageAltText?: string;
    video?: VideoMedia;
    headline?: Headline;
    subHeadline?: Headline;
    subSubHeadline?: Headline;
    text?: RichText;
    callToAction?: Link;
    color: 'link' | 'primary' | 'secondary' | 'brand' | 'campaign';
    mediaHorizontalAlignment: HorizontalAlignment;
    verticalAlignment?: VerticalAlignment;
    horizontalAlignment?: HorizontalAlignment;
    mediaBackgroundColor: string;
    alternativeBackgroundColor?: string;
    alternativeTextColor?: string;
};

export type M80MediaAndTextBundleModule = Module & {
    type: 'm80MediaAndTextBundleModule';
    headline?: Headline;
    callToAction?: Link;
    mediaAndTextItems: M80MediaAndTextBundleItem[];
};

export type M80MediaAndTextBundleItem = MediaAndTextItem | PageReference;

export type M90QuoteModule = Module & { type: 'm90QuoteModule'; quote?: string; author?: string };

export type M140AnchorModule = { id: string; type: 'm140AnchorModule'; identifier: string };

export type M100RichTextModule = Module & {
    type: 'm100RichTextModule';
    headline?: Headline;
    subHeadline?: Headline;
    subSubHeadline?: Headline;
    text?: RichText;
};

export type M130ScriptTypes = 'Cookies' | 'Jobs' | 'Spotboks';

export type M130CustomScriptModule = Module & {
    headline?: Headline;
    type: 'm130CustomScript';
    script?: M130ScriptTypes;
};

export type M120AccordionListModule = Module & {
    type: 'm120AccordionListModule';
    headline?: Headline;
    accordionItems: AccordionItem[];
};
/* eslint-disable */
export type PageTypes = {
    p60ModulePage: ComponentType | any;
    p100CheckoutPage: ComponentType | any;
    p90BasketPage: ComponentType | any;
    p90BasketPage1: ComponentType | any;
    p140NotFoundPage: ComponentType | any;
    p150ErrorPage: ComponentType | any;
    p160ArticlePage: ComponentType | any;
    articleListPage: ComponentType | any;
    p120CreateLoginPage: ComponentType | any;
    p125MyProfilePage: ComponentType | any;
    receiptPage: ComponentType | any;
    p40ProductListPage: ComponentType | any;
    p80FavoritePage: ComponentType | any;
    p70FindStores: ComponentType | any;
    p71StoreDetailsPage: ComponentType | any;
    brandsListPage: ComponentType | any;
    p50ProductDetailsPage: ComponentType | any;
    p10SearchPage: ComponentType | any;
    giftCardBalancePage: ComponentType | any;
    p20FrontPage: ComponentType | any;
};
/* eslint-enable */

export type Pages =
    | P60ModulePage
    | P100CheckoutPage
    | P90BasketPage
    | P140NotFoundPage
    | P150ErrorPage
    | P160ArticlePage
    | P165ArticleListPage
    | P120CreateLoginPage
    | P125MyProfilePage
    | ReceiptPage
    | (P60ModulePage & P140NotFoundPage & P150ErrorPage)
    | P40ProductListPage
    | P80FavoritePageProps
    | P70FindStoresPage
    | P71StoreDetailsPage
    | BrandsListPage
    | P50ProductDetailsPage
    | P10SearchPage
    | GiftCardBalancePage
    | P20FrontPage;

export type ModulePage = Page;

export type OpenGraph = {
    title?: string;
    description?: string;
    image?: ImageMedia | null;
    type?: string;
} & {
    [key: string]: string;
};

export type Page = (Globalizable & Localizable) & {
    meta?: {
        url?: string;
        title?: string;
        description?: string;
        hideFromRobots?: boolean;
        canonical?: Link;
        openGraph?: OpenGraph;
        schemaMarkup?: string;
    };
    statusCode?: number;
    buttonColor?: ButtonColorProps;
    buttonLink?: {
        id: string;
        text: string;
        title: string;
        url: string;
    };
    pointsHeader?: string;
    pageElements?: PageElement[];
    bottomPageElements?: PageElement[];
    breadcrumb?: Breadcrumb;
    announcementBar?: AnnouncementBar;
    productCategoryLinks?: Record<string, any>;
    id: string;
    type: string;
    /**
     * @data can be extended to have other data types if needed
     * It is added on page level to make it more easy to extend
     * and is a place for different page type to define their additional
     * data types.
     */
    data?: SSR_Data;
};

export type PersonalizedPage = (Globalizable & Localizable) & {
    pageElements: PageElement[];
    id: string;
    type: string;
};

export type P60ModulePage = ModulePage & { type: 'p60ModulePage' };

export type P60ModulePage1 = ModulePage & { type: 'p60ModulePage1' };

export type P120CreateLoginPage = ModulePage & {
    type: 'p120CreateLoginPage';
    createLoginText: string;
};

export type P125MyProfilePage = ModulePage & { type: 'p125MyProfilePage' };
export type BrandsListPageProps = ModulePage & { type: 'brandsListPage' };

export type P160ArticlePage = ModulePage & {
    type: 'p160ArticlePage';
    article: Article;
    relatedArticles?: Article[];
    heroHeader?: string;
    heroImage: ImageMedia;
    heroImageAltText?: string;
};
export type Article = {
    createdBy: string;
    productListHeader?: string;
    productIds?: string[];
    url: string;
    pageTitle: string;
    pageDescription: string;
    pageImage: Media;
    pageImageAltText?: string;
    parentName: string;
    tags: string[];
    creationDate: string;
    heroTheme?: 'Light' | 'Dark';
};

export type P165ArticleListPage = ModulePage & {
    type: 'articleListPage';
    articles: Article[];
    categories?: Link[];
    heroHeader?: string;
    heroTheme?: 'Light' | 'Dark';
    heroImage: ImageMedia;
    heroImageAltText?: string;
    middlePageElements: PageElement[];
    callToAction?: Link;
    headline?: Headline;
    subHeadline?: Headline;
};

export type P100CheckoutPage = ModulePage & { type: 'p100CheckoutPage' };

export type P140NotFoundPage = ModulePage & { type: 'p140NotFoundPage' };

export type P90BasketPage = ModulePage & { type: 'p90BasketPage' };

export type P150ErrorPage = ModulePage & { type: 'p150ErrorPage' };

export type P20FrontPage = ModulePage & { type: 'p20FrontPage' };

export type P10SearchPage = ModulePage & { type: 'p10SearchPage' };

export type ReceiptPage = ModulePage & { type: 'receiptPage' };
export type BrandsListPage = ModulePage & { type: 'brandsListPage' };

export type P70FindStoresPage = ModulePage & {
    type: 'p70FindStores';
    data: { store?: StoreResponse; storeList?: StoreResponse };
};

export type P71StoreDetailsPage = ModulePage & {
    type: 'p71StoreDetailsPage';
    storeId: string;
    image: ImageMedia;
    imageAlt: string;
    description: string;
};

export type GiftCardBalancePage = ModulePage & {
    type: 'giftCardBalancePage';
    page: { headline?: Headline; descriptionText?: string; pageElements?: PageElement[] };
};

export type P40ProductListPage = ModulePage & {
    dataConfiguration: string;
    dataType?: keyof typeof UmbracoAlgoliaCategories;
    parentDataType?: keyof typeof UmbracoAlgoliaCategories;
    parentDataConfiguration?: string;
    heroImage: ImageMedia;
    heroImageAltText: string;
    type: 'p40ProductListPage';
    categories?: TCategoryPickerItem[];
};

export type HeaderTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

export type HeaderStyle = TextVariants;

export type HorizontalAlignment = 'left' | 'right';

export type VerticalAlignment = 'top' | 'center' | 'bottom';

export interface Product {
    name?: string;
    url?: string;
    image?: ImageMedia;
}

export interface ProductDetails {
    images?: ImageMedia[];
    variations?: Variation[];
    categories?: ProductCategory[];
    splashes?: Splash[];
}

export type ProductCategory = object;

export interface AccordionItem {
    headline: string;
    description: RichText;
}

export type RichText = string;

export interface Headline {
    text?: string;
    tag: HeaderTag;
    style?: HeaderStyle;
    textColor?: string;
}

export interface Media {
    type?: string;
    id: string;

    /** Source of media. */
    src: string;
    width: number;
    height: number;
    caption?: string;
    name?: string;
}

export type FocalPoint = { left: number; top: number };

export type ImageMedia = Media & { alt?: string; focalPoint?: FocalPoint };

export interface VideoMedia {
    src: string;

    /** Should the video loop */
    loop: boolean;

    mute: boolean;

    theaterMode: boolean;

    /** Should the video start on load */
    autoPlay: boolean;
}

export type Basket = (Globalizable & Localizable) & {
    id: string;
    quantitySum: number;
    totals: Totals;
    lineItems: LineItem[];
};

export interface ValidatedBasketViewModel {
    success?: boolean;
    validationMessages?: string[];
    culture?: string;
    market?: string;

    /** @format double */
    quantitySum?: number;
    totals?: Totals;
    lineItems?: LineItem[];
    type?: string;
    id?: string;
}

export interface UspItem {
    id: string;
    image: ImageMedia;
    imageAltText?: string;
    headline: string;
    text?: string;
    link?: Link;
}

export interface Totals {
    subTotal: number;
    shippingTotal: number;
    taxTotal: number;
    handlingTotal: number;
    total: number;
}

export interface LineItem {
    variation: Variation;

    /** @format double */
    quantity: number;
    unitPrice: Money;
    discountedUnitPrice?: Money;
    linePrice: Money;
    discountedLinePrice?: Money;
    id?: string;
}

export interface StockStatus {
    quantityAvailable: number;
}

export type Variation = (Globalizable & Localizable) & {
    sku: string;
    name: string;
    url?: string;
    image?: ImageMedia;
    type: string;
};

export type VariationDetails = Variation & {
    images?: ImageMedia[];
    price: Money;
    discountedPrice?: Money;
    stockStatus: StockStatus;
};

export interface Splash {
    text?: string;
}

export interface Money {
    /** @format double */
    amount: number;
}

export interface Frame {
    header?: Header;
    footer?: Footer;
    navigation?: Navigation;
    search?: Search;
    market: Market;
    marketSelector?: MarketSelector;
    productSettings?: ProductLabelSettings;
    settings?: SiteSettings;
    id?: string;
    culture?: string;
    type?: string;
}

export interface Member {
    memberToken: string;
    marketingPreferences: MarketingPreferences;
}

export interface MarketingPreferences {
    smsAllowed: boolean;
    emailAllowed: boolean;
    soMeAllowed: boolean;
}

export interface Header {
    id?: string;
    textColor?: string;
    backGroundColor?: string;
    campaignBannerLink?: Link;
    topBannerTextColor?: string;
    topBannerBackGroundColor?: string;
    topBanner?: string;
    uspTextColor?: string;
    uspBackgroundColor?: string;
}

export interface Footer {
    id?: string;
    contactText?: RichText;
    logoImage?: ImageMedia;
    newsletterText?: RichText;
    newsletterLink?: Link;
    menu?: FooterNavigationNode[];
    paymentIcons?: FooterIcon[];
    additionalIcons?: FooterIcon[];
    trustpilotLink: Link;
    rate: RichText;
}

export interface FooterNavigationNode {
    header: string;
    children?: SubFooterNavigationNode[];
    id?: string;
}

export interface SubFooterNavigationNode {
    link: Link;
    id?: string;
}

export interface FooterIcon {
    image: ImageMedia;
    title?: string;
    link?: Link;
}

export interface Navigation {
    mainMenu?: NavigationNode[];
    metaMenu?: MetaNavigationNode;
    id?: string;
}

export interface Search {
    id?: string;
    SearchShortcuts: Category[];
    SearchPageSize?: number;
}

export interface ReceiptData {
    header: string;
    standardDeliveryHeader: string;
    supportHeadline: string;
    supportText: string;
    SupportPhoneButton: Link;
}

export interface Market {
    currency?: Currency;
    pricesIncludeTax?: boolean;
    id?: string;
    orderRequirements?: string;
    orderRequirementTitle?: string;
    orderRequirementsLink?: string;
    membershipTermsConsentLabel?: string;
    membershipTermsContent?: string;
    membershipTermsLink?: string;
    personalDataPolicyLabel?: string;
    personalDataPolicyContent?: string;
    personalDataPolicyLink?: string;
    checkoutUsps?: USPProps['usps'];
    uniqueSellingPoints?: USPProps['usps'];
    checkoutFaq?: FaqProps['faqs'];
    receipt?: ReceiptData;
    deliveryBannerInfo?: string;
    freightPolicyText?: string;
    freightPolicyTitle?: string;
    minimumBasketTotalForFreeShipping?: number;
}

export interface MarketSelector {
    markets?: MarketSelectorMarket[];
}

export interface Localizable {
    culture: string;
}

export interface Globalizable {
    market: string;
}

export interface SiteSettings {
    googleTagManagerId?: string;
    googleTagManagerEnvironment?: string;
    raptorCustomerId?: string;
    pageReferences: PageReferences;
}

export interface ProductLabelSettings {
    productLabels: ProductLabelSetting[];
    showFreightPrice: boolean;
    showSortByRatings?: boolean;
    pageSize: number;
    shouldAllowPersonalization?: boolean;
}

export interface ProductLabelSetting {
    identifier: string;
    labelText: string;
    textColor: string;
    backgroundColor: string;
}

export interface PageReferences {
    basketPage?: Link;
    checkoutPage?: Link;
    receiptPage?: Link;
    productPage?: Link;
    searchPage?: Link;
    termsAndConditionsPage?: Link;
    gdprPage?: Link;
    storesPage?: Link;
    notFoundErrorPage?: Link;
    serverErrorPage?: Link;
    loginPage?: Link;
    myProfilePage?: Link;
    createLoginPage?: Link;
    favoritesPage?: Link;
    brandsOverviewPage?: Link;
    campaignPage?: Link;
    preferenceCenterPage: Link;
    mixAndMatchPage?: Link;
    bundlePage?: Link;
    bastardPage?: Link;
}

export interface Redirect {
    type: 'redirect';
    destination: string;
    permanent: boolean;
}

export interface NavigationNode {
    link: Link;
    image?: ImageMedia;
    mobileImage?: ImageMedia;
    alternateLinkHeader?: string;
    children?: NavigationNode[];
    promotedChildren?: PromotedNavigationNode[];
    id?: string;
}

export interface MetaNavigationNode {
    bottomLink: Link;
    links?: Link[];
    DesktopLinks?: NavigationNode[];
    UspLinks?: UspItem[];
    id?: string;
}

export interface PromotedNavigationNode {
    image: ImageMedia;
    link: Link;
    text?: string;
}

export interface Currency {
    symbol?: string;
    currency?: string;
    id?: string;
}

export interface MarketSelectorMarket {
    label?: string;
    languages?: MarketSelectorLanguage[];
    id?: string;
}

export interface Link {
    text?: string;
    title?: string;
    url?: string;
    target?: string;
    id?: string;
}

export interface MarketSelectorLanguage {
    text?: string;
    title?: string;
    url?: string;
    target?: string;
    id?: string;
    culture?: string;
}

export interface MarketBanner {
    text?: string;
    buttonText?: string;
    dismissButtonText?: string;
    selectOtherMarketText?: string;
    selectMarketLabel?: string;
    marketLink?: Link;
}

export interface PageElement {
    id?: string;
    type?: string;
}

export interface PageReference {
    parentName?: string;
    alias?: 'pageReference';
    pageTitle?: string;
    pageDescription?: string;
    pageImage?: ImageMedia;
    callToAction?: Link;
}

export interface Breadcrumb {
    items?: Link[];
}

export interface AnnouncementBar {
    textAlignment?: string;
    textColor?: string;
    backgroundColor?: string;
}

export interface BasketListParams {
    culture: string;
    market: string;
}

export interface BasketLineCreateParams {
    culture: string;
    market: string;
    sku: string;

    /** @format double */
    quantity: number;
}

export interface BasketLineUpdateParams {
    culture: string;
    market: string;

    /** @format double */
    quantity: number;
    sku: string;
}

export interface BasketLineDeleteParams {
    culture: string;
    market: string;
    sku: string;
}

export interface ContentFrameListParams {
    url: string;
}

export interface ContentPersonalizedFrameListParams {
    url: string;
}

export interface ContentMarketBannerListParams {
    pageId: string;
    culture: string;
    market: string;
    detectedMarket?: string;
}

export interface ContentModuleListParams {
    pageId: string;
    culture: string;
    market: string;
    moduleId: string;
}

export interface ContentPageListParams {
    url: string;
}

export interface ContentPersonalizedPageListParams {
    url: string;
}

export interface ContentTranslationsListParams {
    culture: string;
}

export interface MarketingSubscriptionListParams {
    email: string;
}

export interface MarketingSubscriptionCreateParams {
    email: string;
}

export interface MarketingSubscriptionDeleteParams {
    email: string;
}

export interface SearchAutoCompleteListParams {
    culture: string;
    market: string;
    query?: string;
    id?: string;
    pageSize?: number;
}

export interface SearchResultBySearchIdListParams {
    culture: string;
    market: string;
    query?: string;
    searchId?: string;
    pageSize?: number;
    page?: number;
}

export interface SearchResultListParams {
    culture: string;
    market: string;
    query?: string;
    category?: string;
    pageSize?: number;
    page?: number;
}
