import { CommerceApiConfig } from '~/lib/data-contract/salesforce';
import { getCSRF } from '~/services/csrf/csrf-resolver';
import { checkRequiredParameters, createOcapiFetch } from './utils';

class OcapiShopperGiftCertificates {
    private fetch;

    constructor(config: CommerceApiConfig) {
        this.fetch = createOcapiFetch(config);
    }

    async getGiftCertificate(...args: any) {
        const required = ['gcnumber', 'gccvv'];
        const requiredParametersError = checkRequiredParameters(args[0], required);

        if (requiredParametersError) {
            return requiredParametersError;
        }

        const {
            csrf: { token },
        } = await getCSRF();

        const { gcnumber, gccvv } = args[0].parameters;

        const result = await this.fetch(
            `s/imerco/giftcertificate/status?gcnumber=${gcnumber}&gccvv=${gccvv}&csrf_token=${token}`,
            'GET',
            args,
            'lookup',
            undefined,
            true
        );

        if (result.success) {
            return result.data;
        }

        throw new Error(result.errorData);
    }
}

export default OcapiShopperGiftCertificates;
