export const getCSRF = async () => {
    const tokenReq = await fetch(
        '/api/salesforce/ocapi/on/demandware.store/Sites-imerco-Site/da_DK/CSRF-Generate',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    const {
        error,
        csrf: { token },
    } = await tokenReq.json();

    if (error) {
        throw new Error(error.errorMessage);
    }

    return { error, csrf: { token } };
};
