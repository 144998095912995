import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { FC } from 'react';

type AppInsightsProviderProps = {
    plugin: ReactPlugin;
    children: React.ReactNode;
};

export const AppInsightsProvider: FC<AppInsightsProviderProps> = ({
    children,
    plugin,
}: AppInsightsProviderProps) => {
    return <AppInsightsContext.Provider value={plugin}>{children}</AppInsightsContext.Provider>;
};
