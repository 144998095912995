import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Page } from '~/lib';

/**
 * Find default query options as
 * `QueryObserverOptions` at https://github.com/tannerlinsley/react-query/blob/master/src/core/types.ts
 */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // Disabled since we don't rely on realtime data
            refetchOnWindowFocus: false,

            // Time before revalidating data. Set to 5min to
            // match reactQuery's default cache time
            staleTime: 300000,
        },
    },
});

export const RequestProvider = ({
    children,
    data,
}: {
    children: ReactNode;
    data?: Page['data'];
    queryKey?: string;
}) => {
    if (data && data.product) {
        queryClient.setQueryData(['variant', data.product.id], { data: [data.product] });
    }
    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            {children}
        </QueryClientProvider>
    );
};
