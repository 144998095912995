import { Store } from '~/lib/data-contract/salesforce';

type Props = {
    stores: Store[];
    searchQuery: string;
    minSearchQueryLength?: number;
};

export const filterStores = ({ stores = [], searchQuery, minSearchQueryLength = 2 }: Props) => {
    if (!searchQuery || searchQuery.length < minSearchQueryLength) {
        return stores;
    }
    const queryToLowerCase = searchQuery.toLowerCase();
    return stores.filter((store) => {
        const cityToLowerCase = store.city.toLowerCase();
        const postalCode = store.postalCode.toLowerCase();
        return cityToLowerCase.includes(queryToLowerCase) || postalCode.includes(queryToLowerCase);
    });
};

// Removes teh city area from the city name fx NV, C, NØ, SØ, SV etc so the city names cane be faltered and compared
export const trimCityArea = (str: string = '') => {
    const lastSpaceIndex = str.lastIndexOf(' ');

    if (lastSpaceIndex !== -1 && str.length - lastSpaceIndex - 1 <= 2) {
        return str.substring(0, lastSpaceIndex);
    }

    return str || '';
};
