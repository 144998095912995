export type translationInterpolationKeys = {
    [key: string]: string;
};

export const interpolate = (text: string, interpolationKeys: translationInterpolationKeys) => {
    return text.replace(/({{\w*}})/g, (_, unfilteredKey: string) => {
        const key = unfilteredKey.substring(2, unfilteredKey.length - 2);
        return interpolationKeys[key];
    });
};
