import React, { useMemo } from 'react';
import { Translations } from '../model/translationModel';

interface TranslationProviderContextValue {
    culture: string;
    initialTranslations?: Translations | null;
    debug?: boolean;
}

export const TranslationContext = React.createContext<TranslationProviderContextValue>({
    culture: 'da-dk',
});

export const TranslationProvider = ({
    children,
    culture,
    initialTranslations,
    debug,
}: React.PropsWithChildren<TranslationProviderContextValue>) => {
    /**
     * Memoizing a context value will prevent unnecessary re-renders because
     * the "reference" will be the same for the object when doing comparisons
     * in consequent renders unless the passed values change.
     */
    const memoizedContextValue = useMemo(
        () => ({
            culture,
            initialTranslations,
            debug,
        }),
        [culture, initialTranslations, debug],
    );

    return (
        <TranslationContext.Provider value={memoizedContextValue}>
            {children}
        </TranslationContext.Provider>
    );
};
